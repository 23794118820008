import { Button, Col, Form, Row } from 'react-bootstrap';
import Layout from 'components/layout';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Slider from '../components/slider';
import axios from 'axios';
import icon from 'images/icon.svg';

const Pricing = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [lastName, setLastName] = useState('');
    const [jobTitle, setJobTitle] = useState('');

    const resetFormData = () => {
        setCompanyName('');
        setEmail('');
        setLastName('');
        setName('');
        setJobTitle('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            lastName: lastName,
            company: companyName,
            position: jobTitle,
        };
        submitData(data);
        resetFormData();
    };

    const submitData = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=pricing",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options)
    }

    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>A small investment sales performance management</title>
                    <meta name="description" content="WIth QuotaCal, our clients have seen an average growth of 115% in commissionable sales which resulted in average 3650% Return-on-Investment (ROI)" />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title">A small investment sales performance management</p>
                        <p className="description">With QuotaCal, our clients have seen an average growth of 115% in commissionable sales which resulted in average 3650% Return-on-Investment (ROI)</p>
                    </div>
                </div>
                <Row style={{ marginBottom: '50px', marginTop: '50px' }}>
                    <Col md={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }} style={{ background: 'rgba(196, 101, 255, 0.3)', borderRadius: '20px', fontFamily: 'Inria Sans', fontSize: '24px' }}>
                        <p style={{ textAlign: 'center', color: '#5958C7', fontSize: '48px', fontFamily: 'Inria Sans' }}>Get your quote</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                            <Form style={{ width: '90%', marginBottom: '30px' }} onSubmit={handleSubmit} >
                                <Form.Control className="formControl" value={name} onChange={(e) => setName(e.target.value)} required placeholder="First Name" />
                                <Form.Control className="formControl" value={lastName} onChange={(e) => setLastName(e.target.value)} required placeholder="Last Name" />
                                <Form.Control className="formControl" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email" />
                                <Form.Control className="formControl" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required placeholder="Company Name" />
                                <Form.Control className="formControl" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required placeholder="Job Title" />
                                <Button className="button" type="submit">
                                    Submit</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 1 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <Row>
                                <p className='head'>Customized to your needs</p>
                                <p className="desc">Our packages allow you to choose on what modules you need in your sales motivation tool. The pricing is flexible to adapt any type or organization, size of sales team and other complexities.</p>
                            </Row>
                            <Row>
                                <p className="head">Why do Businesses choose us?</p>
                                <p className="desc">We get you the most out of your sales incentive structures by building based on the key objectives that you strive for. Also, they get a very good ROI in QuotaCal.</p>
                            </Row>
                        </div>
                    </Col>
                </Row >
                <div>
                    <p className='heading'>Hear from our clients</p>
                </div>
                <Slider />
            </div >
        </Layout>
    )
};

export default Pricing;
